<template>
  <div class="totem-form fill-height" v-if="chromeData">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter une fiche Totem</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <div class="mb-s">
          <h5 class="mb-xxs">Nom du Totem *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">Famille d'animaux</h5>
          <SelectCustom
            name="category"
            placeholder="Choisissez une famille"
            :hasParent="category"
            :data="chromeData.lists.totemCategories"
          />
        </div>

        <div class="photos m-s">
          <h5 class="mb-xxs">Photo *</h5>
          <Upload
            ref="uploadPhotos"
            name="photos[]"
            icon="image"
            :max="1"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['image/*'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Caractéristiques physiques</h5>
          <textarea name="physics"></textarea>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Comportement et attitude</h5>
          <textarea name="behaviour"></textarea>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Environnement</h5>
          <textarea name="environment"></textarea>
        </label>

        <div class="mb-s">
          <h5 class="mb-xxs">Adjectifs *</h5>

          <div
            class="select-custom-wrapper"
            :id="`scw-${index}`"
            v-for="(item, index) in physicalAdjectives"
            :key="`physicalAdjective-${index}`"
          >
            <SelectCustom
              class="mb-xxs"
              placeholder="Choisissez un adjectif"
              name="adjective[]"
              :data="chromeData.lists.physicalAdjectives"
            />

            <a class="remove" @click.prevent="removePhysicalAdjectives(index)">
              <SvgSprite symbol="ui-close" size="16" />
            </a>
          </div>

          <a
            class="add"
            @click.prevent="addPhysicalAdjectives"
            v-if="physicalAdjectives.length < 50"
          >
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un adjectif</span>
          </a>
        </div>

        <div class="infos mb-s">
          <div class="mb-s">
            <h5 class="mb-xxs">Couleur de floche (intérieur)</h5>
            <FormInput name="insideColor" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Couleur de floche (extérieur)</h5>
            <FormInput name="outsideColor" />
          </div>
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">Autres orthographes du Totem, synonymes</h5>
          <FormInput name="synonyms" />
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">Prononciation suggérée</h5>
          <FormInput name="pronunciation" />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <div class="error mb-s" v-if="photosMissing">
          <h4>Ajoute au moins une photo</h4>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ta fiche Totem est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ta fiche Totem a bien été envoyée. Après vérification, elle sera
          publiée et disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { create } from '@/guides/endpoints/totems'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import FormInput from '@/components/FormInput.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    BackBtn,
    Btn,
    Feedback,
    FormInput,
    SelectCustom,
    Upload,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)

    const chromeData = ref()
    const showAlert = ref(false)
    const photosMissing = ref(false)
    const uploadPhotos = ref()
    const physicalAdjectives = ref<Array<string>>([''])

    const addPhysicalAdjectives = () => {
      physicalAdjectives.value.length += 1
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removePhysicalAdjectives = (index: any) => {
      const adjective = document.querySelector(`#scw-${index}`)

      adjective?.remove()
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    chrome().then(r => {
      chromeData.value = r.data
    })

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        photosMissing.value = false
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })

        create(data)
          .then(d => {
            if (d.data?.status === 200) {
              onSuccess()
            } else {
              onError(d.data)
            }
          })
          .catch(() => {
            onError({
              message:
                "Il semblerait qu'une erreur ce soit glissée quelque part.",
            })
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        loading.value = false
        photosMissing.value = true
        feedbackVisible.value = false
      }
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      physicalAdjectives,
      addPhysicalAdjectives,
      removePhysicalAdjectives,
      category,
      photosMissing,
    }
  },
})
</script>

<style lang="scss" scoped>
.totem-form {
  @include form-base;

  background: $c-white;

  ::v-deep(.select-custom) {
    background-color: $athens-gray;
    border-color: $athens-gray;
  }
}

.select-custom-wrapper {
  display: flex;
  align-items: center;

  ::v-deep(.select-custom) {
    flex: 1;
  }

  .remove {
    padding: 1rem;
    cursor: pointer;
  }
}

@include mq(l) {
  .infos {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: calc(50% - 1rem);
      margin-bottom: 0;
    }
  }
}
</style>
